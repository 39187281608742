<template>
  <div>
    <p>
      {{ $t('trans.change_project') }}
      <select v-model="projectID" @click="choiceProject">
        <option v-for="(title, id) in projectList" :key="id" :value="id">
          {{ title }}
        </option>
      </select>
      <select v-model="langIndex" @click="choiceLanguage">
        <option v-for="(lang, id) in languageList[projectID]" :key="id" :value="id">
          {{ lang }}
        </option>
      </select>
    </p>

    <ul class="navigation">
      <li><a href="#" @click="clickGoTrans" v-if="langIndex">{{ $t('trans.start_trans') }}</a></li>
      <li><a href="#" @click="clickToValid" v-if="langIndex">{{ $t('trans.start_valid') }}</a></li>
      <li><a href="#" @click="clickToManage">{{ $t('trans.start_manage') }}</a></li>
    </ul>
    <div v-if="nowScene === 'trans'">
      <div class="quote">
        <b>{{ $t('trans.word_origin') }}</b>
        {{ trans.origin }}
      </div>
      <div v-if="trans.id">
        <label for="word">{{ $t('trans.word_new') }}</label>
        <textarea type="text" id="word" :placeholder="$t('trans.input_alert')"
                  rows="5" cols="75" v-model="trans.word">
        </textarea>
      </div>
      <div v-if="trans.id">
        <button @click="clickSubmitTrans">{{ $t('default.submit') }}</button>
      </div>
    </div>
    <div v-else-if="nowScene === 'valid'">
      <div class="quote">
        <b>{{ $t('trans.word_origin') }}</b>
        {{ trans.origin }}

        <br>

        <b>{{ $t('trans.word_use') }}</b>
        {{ trans.word }}
      </div>
      <div v-if="trans.id">
        <b>{{ $t('trans.word_new') }}</b>
        {{ trans.wordUnchecked }}
      </div>
      <div v-if="trans.id">
        <button @click="clickSubmitValidate(0)">{{ $t('default.reject_submit') }}</button>
        <button @click="clickSubmitValidate(1)">{{ $t('trans.need_amend_submit') }}</button>
        <button @click="clickSubmitValidate(2)">{{ $t('default.pass_submit') }}</button>
      </div>
    </div>
    <div v-else-if="nowScene === 'manageKK'" class="manager-container">
      <div class="manager-row" style="display: flex; align-items: center">
        <div class="column" style="flex: 0 1 20%">
          <label for="file-list">{{ $t('trans.file_list') }}</label>
          <select v-model="manage.fileID" name="file-list" id="file-list">
            <option v-for="(name, id) in fileList" :key="id" :value="id">
              {{ name }}
            </option>
          </select>
        </div>

        <div class="column" style="flex: 0 1 20%">
          <button @click="() => this.manage.showLangList = !this.manage.showLangList">
            {{ $t('trans.show_lang_list') }}
          </button>
          <div v-if="manage.showLangList">
            <div v-for="(name, id) in enumLanguage" :key="id" :value="id">
              <input type="checkbox" :id="'lang' + id" :name="'lang' + id">
              <label :for="'lang' + id">{{ name }}</label>
            </div>
          </div>
        </div>

        <div class="row" style="flex: 0 1 20%">
          <label for="upload-file">{{ $t('trans.upload_file') }}</label>
          <input type="file" id="upload-file" name="upload-file">
        </div>
      </div>
      <div>
        <button @click="submitFile">{{ $t('trans.upload_file_submit') }}</button>
      </div>

      <!-- TODO 导出文件 -->
    </div>
    <div v-else>
      <p class="choice-scene">
        {{ $t('trans.fail_scene') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    trans: {
      id: null,
      origin: '获取中...',
      word: '',
      wordUnchecked: '',
    },
    manage: {
      fileID: 0,
      showLangList: 0,
      otherLang: [],
    },

    nowScene: null,
    projectList: {},
    projectID: Number(localStorage.getItem('trans:projectID')),
    languageList: {},
    langIndex: null,
    fileList: null,
    projectUnionID: null,
    projectTitle: null,
    projectOriginLang: null,
    enumLanguage: {
      1: '英文',
      2: '简体中文',
      4: '繁体中文',
    },
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    // 初始化项目相关
    this.$http.get('api/trans/projects')
      .then((response) => {
        response.data.map((project) => {
          this.$set(this.projectList, project.id, project.title);
          this.$set(this.languageList, project.id, this.getBitLang(project.lang_list));
          return null;
        });
      })
      .catch((error) => {
        this.$tip.fire(error.response.data.message);
      });

    // TODO 增加项目进度展示
    if (this.projectID) {
      this.getProjectInfo();
    }
  },
  methods: {
    // 获取项目状态
    getProjectInfo() {
      this.$http.get(`api/trans/status/${this.projectID}`)
        .then((response) => {
          const { data } = response;
          this.fileList = [this.$t('trans.file_new')];
          data.files.map((value, index) => this.$set(this.fileList, index + 1, value.file_name));
          this.projectUnionID = data.project.union_id;
          this.projectTitle = data.project.title;
          this.projectOriginLang = data.project.language;
        })
        .catch((error) => {
          this.$tip.fire(error.response.data.message);
        });
    },
    // 从十进制值中，拆解二进制值对应的的语言 ( 16777215 is MySQL medium int )
    getBitLang(val) {
      const bitVal = {};
      for (let i = 1; i < 16777215; i *= 2) {
        if (val & i) { // eslint-disable-line no-bitwise
          bitVal[i] = this.enumLanguage[i];
        }
      }

      return bitVal;
    },
    // 选择项目
    choiceProject() {
      localStorage.setItem('trans:projectID', this.projectID);
      this.langIndex = null;

      this.getProjectInfo();
    },
    // 选择语言
    choiceLanguage() {
      localStorage.setItem('trans:langIndex', this.langIndex);
      this.nowScene = null;
    },
    // 管理 - 上传文件
    async submitFile() {
      let tag = '';
      if (this.manage.fileID === 0) {
        const input = await this.$tip.fire({
          title: this.$t('trans.new_file_alert'),
          input: 'text',
          inputLabel: this.$t('trans.new_file_by_tag'),
          inputValue: tag,
          showCancelButton: true,
          inputValidator: (value) => {
            if (value.length > 100) {
              return this.$t('default.limit_length', { attribute: this.$t('trans.tag'), min: 0, max: 100 });
            }
            return null;
          },
        });
        if (input.isDismissed === true) return;
        tag = input.value;
      }
      const formData = new FormData();
      const imagefile = document.querySelector('#upload-file');
      formData.append('file', imagefile.files[0]);
      formData.append('fileId', this.manage.fileID);
      formData.append('projectId', this.projectID);
      formData.append('tag', tag);
      formData.append('otherLang[]', this.projectOriginLang);
      this.otherLang.map((value) => formData.append('otherLang[]', value));
      const URLPrefix = {
        aoeonline_3456ty723bipu452rfc7: 'api/trans/import/aoe-ol',
      };
      this.$http.post(URLPrefix[this.projectUnionID], formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    // 前往翻译
    clickGoTrans() {
      this.nowScene = 'trans';

      this.$http.get(`api/trans/untranslated?id=${this.projectID}&lang=${this.langIndex}`)
        .then((response) => {
          if (response.status === 204) {
            this.$tip.fire(this.$t('trans.all_trans_clear'));
            this.trans.id = null;
            this.trans.origin = this.$t('trans.all_trans_clear');
            this.trans.word = '';
          } else {
            const { data } = response;
            this.trans.id = data.id;
            this.trans.origin = data.origin_word;
            this.trans.word = (data.word_unchecked !== '') ? data.word_unchecked : data.word;
          }
        })
        .catch((error) => {
          this.$tip.fire(error.response.data.message);
        });
    },
    // 提交翻译
    clickSubmitTrans() {
      this.$http.post('api/trans/untranslated', {
        id: this.trans.id,
        word: this.trans.word,
      })
        .then((response) => {
          this.$tip.fire(response.data);
        })
        .catch((error) => {
          this.$tip.fire(error.response.data.message);
        });
    },
    // 前往校对
    clickToValid() {
      this.nowScene = 'valid';

      this.$http.get(`api/trans/unchecked?id=${this.projectID}&lang=${this.langIndex}`)
        .then((response) => {
          if (response.status === 204) {
            this.$tip.fire(this.$t('trans.all_trans_clear'));
            this.trans.id = null;
            this.trans.origin = this.$t('trans.all_trans_clear');
            this.trans.word = '';
          } else {
            const { data } = response;
            this.trans.id = data.id;
            this.trans.origin = data.origin_word;
            this.trans.word = data.word;
            this.trans.wordUnchecked = data.word_unchecked;
          }
        })
        .catch((error) => {
          this.$tip.fire(error.response.data.message);
        });
    },
    // 提交校对
    clickSubmitValidate(result) {
      // 0: 驳回; 1: 需校正; 2: 采用
      this.$http.post('api/trans/unchecked', {
        id: this.trans.id,
        result,
      })
        .then((response) => {
          this.$tip.fire(response.data);
        })
        .catch((error) => {
          this.$tip.fire(error.response.data.message);
        });

      this.clickToValid();
    },
    // 前往管理
    clickToManage() {
      this.nowScene = 'manage';
    },
  },
};
</script>

<style lang="scss">
hr {
  border-top: 1px solid #ffebad;
}

.quote {
  background: #cccccc;
  padding: 4px;
}

.choice-scene {
  font-size: 2rem;
  color: cornflowerblue;
}

.manager-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .manager-row {
    .column {
      flex: 3;
    }
  }
}

/* Large */
.navigation {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  list-style: none;
  margin: 0;
  background: deepskyblue;
}

.navigation a {
  text-decoration: none;
  display: block;
  padding: 1em;
  color: white;
}

.navigation a:hover {
  background: #1565C0;
}
</style>
